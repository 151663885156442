import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { FiLogOut } from 'react-icons/fi';
import styleSideBar from './styles';
import ListItemSideBar from './Components/ListItemSidebar';
import { useLocation } from 'react-router-dom';
import './styles/styles.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { pendingUsersFetchAction } from '../../store/fetchActions/pendingUsersActions';
import { pendingAccountsFetchAction } from '../../store/fetchActions/userAccounts';
import { useDispatch, useSelector } from 'react-redux';
import { MenuOpen } from '../../assets/icon/index';
import background from '../../assets/icon/Menu.svg';
import { validateJwt } from '../../store/fetchActions/loginActions';
import Timer from '../Timer/Timer';
import { useTheme } from 'styled-components';
import { pixTransactionPendingPixFetchAction } from '../../store/fetchActions/pixTransactionsActions';

const useStyles = makeStyles((theme) => styleSideBar(theme));

export default function Sidebar() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const container = useRef(null);
  useOutsideAlerter(container);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleDrawerClose();
        }
      }
      document.addEventListener('click', handleOutsideClick);
    }, [ref]);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    Swal.fire({
      title: 'Deseja realmente sair?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: theme.colors.buttonConfirm,
      cancelButtonColor: theme.colors.buttonBack,
      cancelButtonText: 'Voltar',
      confirmButtonText: 'Sair',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/login');
        localStorage.clear();
      }
    });
  };

  useEffect(() => {
    handleDrawerClose();
    if (location.pathname != '/login' && location.pathname != '/') {
      dispatch(pendingUsersFetchAction());
      dispatch(pendingAccountsFetchAction());
      dispatch(pixTransactionPendingPixFetchAction());
    }
    dispatch(
      validateJwt((res) => {
        res && (navigate('/login'), localStorage.clear());
      }),
    );
  }, [location]);

  const { pagination } = useSelector((state) => state.pendingUsers);
  const { paginationPendingScrows } = useSelector((state) => state.pendingScrows);
  const { paginationPendingPixs } = useSelector((state) => state.pendingTransactionsPixs);

  console.log('paginationPendingPixs', paginationPendingPixs);

  const { userAccounts } = useSelector((state) => state);

  const validateJwts =
    pathname != '/' &&
    pathname != '/login' &&
    JSON.parse(window.atob(localStorage.getItem('is_authenticate')?.split('.')[1])).time;

  const jwtDateTime = new Date(validateJwts);
  jwtDateTime.setSeconds(jwtDateTime.getSeconds());

  return (
    <div className={pathname === '/login' ? classes.hidden : classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <div className="imagemLogo" onClick={() => navigate('/dashboard')}></div>
          </div>
          <div className={classes.barIconsContainer}>
            <div className={classes.barIcons}>{pathname != '/login' && <Timer expiryTimestamp={jwtDateTime} />}</div>
            <FiLogOut className={classes.barIcons} title="Sair" onClick={() => logout()} />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        ref={container}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <MenuOpen onClick={(e) => handleDrawerClose} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => setOpen(true)}
              style={{ background: `url(${background})`, marginLeft: '0.7rem' }}
            ></IconButton>
          )}
        </div>

        <ListItemSideBar
          classes={classes}
          pending={pagination.totalResult}
          pendingAccount={userAccounts.pagination.totalResult}
          pendingScrows={paginationPendingScrows.totalResult}
          pendingPix={paginationPendingPixs.totalResult}
        ></ListItemSideBar>
      </Drawer>
    </div>
  );
}
